<template>
  <div class="container">
    <Header active="expert" />
    <div class="main">
      <el-carousel class="banner" @change="bannerChange" interval="6000">
        <el-carousel-item>
          <div class="item">
            <transition
              enter-active-class="animate__animated animate__fadeIn cus-animated"
              leave-active-class="animate__animated animate__fadeOut"
            >
              <div v-if="b1show" key="ban01" class="text">
                <div class="title">专业服务让您省时省力</div>
                <div class="desc">
                  职称评定、创业融资、人才评定、课题申报、财务法务教育服务及风险防范
                </div>
              </div>
            </transition>
            <img
              class="img"
              :src="require('../../../assets/images/website/expert.jpg')"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="data">
        <el-tabs type="border-card">
          <el-tab-pane label="聘任专家库">
            <div class="sift">
              <el-row class="region">
                <el-col :span="2" class="tle">选择分类：</el-col>
                <el-col :span="22" class="items">
                  <span class="item"
                    ><a
                      href="javascript:void"
                      @click="epyClassifySearch('', '')"
                      >全部分类</a
                    ></span
                  >
                  <span
                    class="item"
                    v-for="classify in classifys"
                    :key="classify.code"
                    ><a
                      href="javascript:void"
                      @click="epyClassifySearch(classify.code, classify.name)"
                      >{{ classify.name }}</a
                    ></span
                  >
                </el-col>
              </el-row>
              <!-- <el-row class="years">
                <el-col :span="2" class="tle">选择国家：</el-col>
                <el-col :span="22" class="items">
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch('')"
                      >全部国家</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch('')"
                      >中国</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch(2022)"
                      >美国</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch(2021)"
                      >德国</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch(2020)"
                      >英国</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch(2019)"
                      >法国</a
                    ></span
                  >
                </el-col>
              </el-row> -->
            </div>
            <div class="content">
              <el-row :gutter="20">
                <el-col
                  :span="8"
                  v-for="expert in epyExperts"
                  :key="expert.code"
                >
                  <div class="item">
                    <el-row>
                      <el-col :span="24">
                        <el-row>
                          <el-col :span="12"
                            ><img class="photo" :src="expert.imagePath"
                          /></el-col>
                          <el-col :span="12">
                            <div class="name text-short">
                              {{ expert.name }}({{ expert.countryText }})
                            </div>

                            <el-row class="btd gender">
                              <el-col :span="12">性别：</el-col>
                              <el-col :span="12">{{
                                expert.genderText
                              }}</el-col>
                            </el-row>
                            <el-row class="btd qualify">
                              <el-col :span="12">学历：</el-col>
                              <el-col :span="12" class="qualify">{{
                                expert.qualifyText
                              }}</el-col>
                            </el-row>
                            <el-row class="btd classify">
                              <el-col :span="12">分类：</el-col>
                              <el-col :span="12" class="classify">{{
                                expert.classifyText
                              }}</el-col>
                            </el-row>
                            <el-row class="btd classify">
                              <el-col :span="12">民族：</el-col>
                              <el-col :span="12" class="classify text-short"
                                ><span
                                  v-if="
                                    expert.nationText !== undefined &&
                                    expert.nationText !== ''
                                  "
                                  >{{ expert.nationText }}</span
                                ><span v-else>未知</span></el-col
                              >
                            </el-row></el-col
                          >
                        </el-row>
                        <div class="btd brief">
                          <span
                            v-if="
                              expert.brief !== undefined && expert.brief !== ''
                            "
                          >
                            {{ expert.brief }}
                          </span>
                          <span v-else> 暂无简要介绍 </span>
                        </div>
                        <el-row class="btn btd">
                          <el-col :span="24"
                            ><el-button
                              type="primary"
                              icon="el-icon-view"
                              size="mini"
                              @click="view(expert.code)"
                              >查看详细资料</el-button
                            ><el-button
                              type="warning"
                              @click="serviceApply(expert.code)"
                              icon="el-icon-s-promotion"
                              size="mini"
                              >业务合作申请</el-button
                            ></el-col
                          >
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <Pagination :callback="epyExpertCallback" ref="epyExpertPage" />
          </el-tab-pane>
          <el-tab-pane label="全球专家库">
            <div class="sift">
              <el-row class="region">
                <el-col :span="2" class="tle">选择分类：</el-col>
                <el-col :span="22" class="items">
                  <span class="item"
                    ><a href="javascript:void" @click="classifySearch('', '')"
                      >全部分类</a
                    ></span
                  >
                  <span
                    class="item"
                    v-for="classify in classifys"
                    :key="classify.code"
                    ><a
                      href="javascript:void"
                      @click="classifySearch(classify.code, classify.name)"
                      >{{ classify.name }}</a
                    ></span
                  >
                </el-col>
              </el-row>
              <el-row class="years">
                <el-col :span="2" class="tle">选择国家：</el-col>
                <el-col :span="22" class="items">
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch('')"
                      >全部国家</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch(156)"
                      >中国</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch(840)"
                      >美国</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch(276)"
                      >德国</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch(826)"
                      >英国</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="countrySearch(250)"
                      >法国</a
                    ></span
                  >
                </el-col>
              </el-row>
            </div>
            <div class="content">
              <el-row :gutter="20">
                <el-col :span="6" v-for="expert in experts" :key="expert.code">
                  <div class="item">
                    <el-row>
                      <el-col :span="24">
                        <el-row>
                          <el-col :span="12"
                            ><img class="photo" :src="expert.imagePath"
                          /></el-col>
                          <el-col :span="12">
                            <div class="name text-short">
                              {{ expert.name }}({{ expert.countryText }})
                            </div>

                            <el-row class="btd gender">
                              <el-col :span="12">性别：</el-col>
                              <el-col :span="12">{{
                                expert.genderText
                              }}</el-col>
                            </el-row>
                            <el-row class="btd qualify">
                              <el-col :span="12">学历：</el-col>
                              <el-col :span="12" class="qualify">{{
                                expert.qualifyText
                              }}</el-col>
                            </el-row>
                            <el-row class="btd classify">
                              <el-col :span="12">分类：</el-col>
                              <el-col :span="12" class="classify">{{
                                expert.classifyText
                              }}</el-col>
                            </el-row>
                            <el-row class="btd classify">
                              <el-col :span="12">民族：</el-col>
                              <el-col :span="12" class="classify text-short"
                                ><span
                                  v-if="
                                    expert.nationText !== undefined &&
                                    expert.nationText !== ''
                                  "
                                  >{{ expert.nationText }}</span
                                ><span v-else>未知</span></el-col
                              >
                            </el-row></el-col
                          >
                        </el-row>
                        <div class="btd brief">
                          <span
                            v-if="
                              expert.brief !== undefined && expert.brief !== ''
                            "
                          >
                            {{ expert.brief }}
                          </span>
                          <span v-else> 暂无简要介绍 </span>
                        </div>
                        <el-row class="btn btd">
                          <el-col :span="24"
                            ><el-button
                              type="primary"
                              icon="el-icon-s-promotion"
                              @click="serviceApply()"
                              size="mini"
                              >业务合作申请</el-button
                            ></el-col
                          >
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <!-- <Pagination :callback="allExpertCallback" ref="allExpertPage" /> -->
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-dialog title="业务合作申请" v-model="applyState">
        <el-form :model="ruleForm" label-width="80px" class="form">
          <el-form-item label="姓名">
            <el-input
              v-model="ruleForm.name"
              placeholder="请填写您的姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input
              v-model="ruleForm.phone"
              placeholder="请填写您的联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="业务描述">
            <el-input
              v-model="ruleForm.description"
              :rows="2"
              type="textarea"
              placeholder="请简要描述您的业务需求"
            ></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="applyState = false">取消</el-button>
            <el-button type="primary" @click="serviceApplySave">
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <Footer />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
