import 'animate.css'
import '../../../assets/icon/font-icon.js'
import Header from '../common/Header.vue'
import Footer from '../common/Footer.vue'
import Pagination from '../../../components/Pagination.vue'
import { ElCarousel, ElCarouselItem, ElTabs, ElTabPane, ElDialog, ElSelect, ElOption, ElForm, ElFormItem, ElInput } from 'element-plus'
export default {
    components: {
        Header: Header,
        Footer: Footer,
        Pagination: Pagination,
        [ElCarousel.name]: ElCarousel,
        [ElCarouselItem.name]: ElCarouselItem,
        [ElTabs.name]: ElTabs,
        [ElTabPane.name]: ElTabPane,
        [ElDialog.name]: ElDialog,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElForm.name]: ElForm,
        [ElFormItem.name]: ElFormItem,
        [ElInput.name]: ElInput
    },
    data () {
        return {
            b1show: false,
            applyState: false,
            expertCode: '',
            classifyCode: '',
            classifyName: '',
            epyClassifyCode: '',
            epyClassifyName: '',
            countryCode: '',
            experts: [],
            epyExperts: [],
            classifys: [],
            ruleForm: {
                name: '',
                phone: '',
                description: ''
            }
        }
    },
    mounted () {
        this.color_m = window.const.global.HRP_COLOR_M
        this.color_s1 = window.const.global.HRP_COLOR_S1
        this.b1show = true
        this.retrieveClassify()
        this.search()
        this.employSearch()
    },
    methods: {
        bannerChange (val) {
            if (val === 0) {
                this.b1show = true
                this.b2show = false
            } else {
                this.b1show = false
                this.b2show = true
            }
        },
        async retrieveClassify () {
            var pd = { upperCode: '0' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/expert/classify/retrieveClassifySelect', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.classifys = res.data
            }
        },
        allExpertCallback (data) {
            this.experts = data
        },
        epyExpertCallback (data) {
            this.epyExperts = data
        },
        classifySearch (classifyCode, classifyName) {
            this.classifyCode = classifyCode
            this.classifyName = classifyName
            this.$alert('因保护专家隐私暂不对外展示，如需查看请扫描上方二维码联系我们获取权限', '提示信息')
            // this.search()
        },
        epyClassifySearch (classifyCode, classifyName) {
            this.epyClassifyCode = classifyCode
            this.epyClassifyName = classifyName
            this.employSearch()
        },
        countrySearch (code) {
            this.countryCode = code
            this.$alert('因保护专家隐私暂不对外展示，如需查看请扫描上方二维码联系我们获取权限', '提示信息')
            // this.search()
        },
        async search () {
            var pd = { classifyCode: this.classifyCode, countryCode: this.countryCode, current: 1, size: 16 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/expert/expertAPC/retrieveExpertPage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.experts = res.data
            }
        },
        async employSearch () {
            var pd = { classifyCode: this.epyClassifyCode }
            this.$refs.epyExpertPage.cusSearchPage(this.BMS_URL + '/expert/expertAPC/retrieveEmployExpertPage', pd, { size: 16 })
        },
        serviceApply (code) {
            this.applyState = true
            this.expertCode = code
        },
        async serviceApplySave () {
            if (this.ruleForm.name === '') {
                this.$alert('请填写姓名', '提示信息')
            } else if (this.ruleForm.phone === '') {
                this.$alert('请填写手机号码', '提示信息')
            } else {
                var content = { name: this.ruleForm.name, phone: this.ruleForm.phone, description: this.ruleForm.description }
                var param = { expertCode: this.expertCode }
                var pd = { app: 'PCW_TTP', label: 'TTP_EXPERT_APPLY', param: JSON.stringify(param), content: JSON.stringify(content) }
                const { data: res } = await this.$http.post(this.BMS_URL + '/call/message/createMessage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.$alert('提交成功请耐心等待', '提示信息').then(() => {
                        this.applyState = false
                    })
                } else {
                    this.$alert('提交失败，请联系在线客服', '提示信息').then(() => {
                        this.applyState = false
                    })
                }
            }
        },
        view (code) {
            this.$router.push({ path: '/website/expertView', query: { code: code } })
        }
    }
}
